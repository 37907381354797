<ion-slides pager="true" :options="slideOpts" v-if="data">
  <ion-slide v-for="entry in data" :key="entry.id">
    <app-card
      class="slideCard"
      :id="entry[cardData.id]"
      :title="entry[cardData.title]"
      :subtitle="prettyPrint(entry[cardData.subtitle])"
      :imgUrl="entry[cardData.imgUrl]"
      :text="prettyPrint(entry[cardData.text])"
      :date="entry[cardData.date]"
      :withActions="withActions"
      :withDetail="withDetail"
      @editEntry="editEntry"
      @deleteEntry="deleteEntry"
      @goToDetail="goToDetail"
    ></app-card>
  </ion-slide>
</ion-slides>
